import Datepicker, {
  DateValueType,
  PopoverDirectionType,
} from "react-tailwindcss-datepicker";
import classNames from "classnames";
import { forwardRef, useEffect, useState, useContext } from "react";
import GlobalContext from "src/context/global-context";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { enUS } from 'date-fns/locale/en-US';
import { it } from 'date-fns/locale/it';

// Register locales
registerLocale('en', enUS);
registerLocale('it', it);

interface TailwindDatePickerProps {
  label?: string;
  placeholder: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  value?: string; // Date string expected
  onChange?: any;
  required?: boolean;
  disabled?: boolean;
  displayFormat?: string;
  popoverDirection?: string;
}

const TailwindDatePicker = forwardRef<HTMLDivElement, TailwindDatePickerProps>(
  (
    {
      label,
      placeholder,
      error,
      className,
      inputClassName = "bg-gray-modern-100",
      onChange,
      value = "",
      required = false,
      disabled = false,
      displayFormat = "dd/MM/YYYY",
      popoverDirection = "down",
      ...props
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<any | null>(value ? value : null);
    const { language } = useContext(GlobalContext);

    useEffect(() => {
      // Update only if value changes
      const formattedValue = value ? value : null;
      if (formattedValue !== selectedValue) {
        setSelectedValue(formattedValue);
      }
    }, [value]); // Depend only on 'value'

    const handleValueChange = (date: any) => {
      if (!date) {
        setSelectedValue(null);
        onChange && onChange(null);
      } else {
        const formatdate = moment(date, displayFormat).toDate()
        console.log(formatdate, "FORM")
        setSelectedValue(formatdate); // Update state
        onChange(formatdate); // Notify parent
      }
    };
    return (
      <div className={className}>
        <label className="block text-[14px]">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && <span className="text-[14px] text-error font-gilroy">* </span>}
              {label}
            </span>
          )}
          
          <DatePicker

            locale={language.toLowerCase()}
            dateFormat="dd/MM/yyyy"
            selected={selectedValue ? selectedValue : null} // Ensure Date object is passed
            onChange={handleValueChange}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText={placeholder}
            maxDate={new Date()} 
            disabled={disabled}
            className={classNames(
              "h-[44px] w-full placeholder-gray-modern-400 appearance-none border-none rounded-[8px] px-4 py-2 text-primary text-[14px] font-gilroy font-medium ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
              inputClassName
            )}
          />
        </label>
        
        {error && (
          <span role="alert" className="block pt-2 text-[12px] text-error">
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

TailwindDatePicker.displayName = "TailwindDatePicker";
export default TailwindDatePicker;
