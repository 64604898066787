import { forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import { CrossIcon } from "../icons/cross-icon";

type InputNameProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  required?: boolean;
  showInfo?: boolean;
  info?: string;
  showClose?: boolean;
  allCaps?: boolean;
  value: string;
  onChange: any;
  clickedClose?: any;
  onlyAlphaAvailable?: boolean;
};

const InputName = forwardRef<HTMLInputElement, InputNameProps>(
  (
    {
      label,
      error,
      type = "text",
      className,
      inputClassName = "bg-gray-modern-100",
      required,
      showInfo = false,
      showClose = false,
      allCaps = false,
      onlyAlphaAvailable = false,
      clickedClose,
      info,
      onChange,
      value,
      ...props
    },
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState(value ?? "");

    useEffect(() => {
      setCurrentValue(value ?? "");
    }, [value]);

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onlyAlphaAvailable) {
        const regex = /^[a-zA-Z0-9' ]{0,255}$/;

        if (regex.test(e.target.value)) {
          setCurrentValue(e.target.value);
          onChange(e.target.value);
        }
      } else {
        const newValue = e.target.value.replace(/[^a-zA-Z\s]/g, ''); // Remove spaces
        setCurrentValue(newValue);
        onChange(newValue);
      }
    };

    return (
      <div className={className}>
        <label className="block text-16px font-gilroy font-medium text-secondary relative">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <input
            type={type}
            ref={ref}
            value={currentValue}
            onChange={onTextChange}
            {...props}
            className={classNames(
              "h-[44px] placeholder-gray-modern-400 border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-4 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
              showClose && classNames("!pr-10"),
              allCaps && "uppercase",
              inputClassName
            )}
          />
          {showClose && (
            <button
              className="absolute bottom-0 right-0 h-[44px] w-[44px] flex items-center justify-center"
              onClick={() => clickedClose && clickedClose()}
            >
              <CrossIcon className="w-3 h-3 text-secondary" />
            </button>
          )}
        </label>
        {showInfo && (
          <span
            role="info"
            className="block pt-2 text-[12px] text-secondary font-gilroy font-medium"
          >
            {info}
          </span>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

InputName.displayName = "InputName";
export default InputName;
