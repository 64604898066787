import { FC, useContext, useEffect, useState, useRef } from "react";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import {
  addPlusIntoPhoneNumber,
  getBidStatusColor,
  getBidStatusName,
  getPaidStatusColor,
  getPaidStatusName,
  makeEventDatesString,
  makeFormattedDateString,
  makeNumberWithFloatingDigits,
  sortEventDates,
} from "src/lib/utils";
import {
  LIST_PAGE_SIZE,
  LIST_PAGE_SIZE_50,
  PaidStatus,
} from "src/lib/constants";
import L from "i18n-react";
import { CollaboratorBidType } from "src/lib/data-types";
import { Dropdown, Empty, MenuProps, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useModalAction } from "src/components/modal-views/context";
import { Event, SearchQueryOptions } from "src/api/types";
import { SortIcon } from "src/components/icons/sort-icon";
import { DocumentIcon } from "src/components/icons/document-icon";
import { makeColumnHeader } from "../table-columns/make-column-header";
import { FileEditIcon } from "../icons/file-edit-icon";
import { DownloadIcon } from "../icons/download-icon";
import GlobalContext from "src/context/global-context";
import InputWithoutLabel from "../ui/input-without-label";
import MonthPicker from "../ui/month-picker";
import DropdownWithoutLabel from "../ui/dropdown-without-label";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import TailwindDatePicker from "../ui/date-picker";
import { CloseCircleIcon } from "../icons/close-circle-icon";
import { useMutation } from "react-query";
import client from "../../api";

const EventBidListView = ({
  isLoading,
  event,
  searchParams,
  setSearchParams,
  total,
  dataSource,
  rowSelection,
  refreshList,
}: {
  isLoading: boolean;
  event: Event;
  searchParams: SearchQueryOptions;
  setSearchParams: any;
  total: number;
  dataSource: CollaboratorBidType[];
  rowSelection: any;
  refreshList: any;
}) => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();
  const navigate = useNavigate();

  const {
    setAlertText,
    setAlertTitle,
    setIsAlertOpened,
    language,
    geoPosition,
  } = useContext(GlobalContext);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 980 ? true : false);
  }

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const onViewContractPDF = (pdfLink: string) => {
    window.open(pdfLink, "_blank");
  };

  const changeContractSignedStatus = (data: CollaboratorBidType) => {
    if (data.contract_signed == 3) return;

    openModal("CHANGE_CONTRACT_STATUS", {
      payload: { cur_status: data.contract_signed, bid_id: data.id },
      refreshCallback: refreshList,
    });
  };

  const changeStatus = (data: CollaboratorBidType) => {
    openModal("CHANGE_STATUS", {
      payload: { cur_status: data.status, bid_id: data.id },
      refreshCallback: refreshList,
    });
  };

  const onEditCompValues = (key: number) => {
    const selectedValues = dataSource.filter((item) => item.key == key);
    if (selectedValues.length == 0) return;

    console.log("Showing COMP - ", event, selectedValues[0]);

    setTimeout(() => {
      openModal("COMP_DETAILS", {
        payload: { ...selectedValues[0], full_dates: event.dates },
        refreshCallback: refreshList,
      });
    }, 300);
  };

  const onDocuments = (key: number) => {
    const selectedValues = dataSource.filter((item) => item.key == key);
    if (selectedValues.length == 0) return;

    setTimeout(
      () =>
        openModal("DOCUMENTS", {
          payload: { bidInfo: selectedValues[0], eventInfo: event },
          refreshCallback: refreshList,
        }),
      300
    );
  };

  const { mutate: cancelContract, isLoading: isCancellingContract } =
    useMutation(client.collaborators.cancelContract, {
      onSuccess: (data) => {
        refreshList();
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_cancel_contract")));
        }
        setIsAlertOpened(true);
      },
    });

  const onCancelContract = (bidID: number) => {
    openModal("CONFIRM", { payload: bidID, callback: onConfirmCancelContract });
  };

  const onConfirmCancelContract = (bidID: number) => {
    cancelContract(bidID);
  };

  const onClickedBid = (data: CollaboratorBidType) => {
    openModal("COLLABORATOR_BID", {
      payload: {
        isBid: true,
        bidInfo: data.info,
        main_photo: data.main_photo,
        photo2: data.photo2,
        photo3: data.photo3,
        photo4: data.photo4,
        photo5: data.photo5,
        photo6: data.photo6,
        photo7: data.photo7,
        photo8: data.photo8,
        photo9: data.photo9,
        video1: data.video1,
        video2: data.video2,
        cv: data.cv,
        lat: data.lat,
        lng: data.lng,
        created_at: data.created_at,
      },
    });
  };

  let columns: ColumnsType<CollaboratorBidType> = [
    {
      title: () =>
        makeColumnHeader("No", "order", searchParams, setSearchParams),
      dataIndex: "key",
      className: "w-[60px]",
      key: "key",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {(searchParams.page - 1) * searchParams.per_page + value + 1}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.event_date")),
          "event_date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "event_dates",
      className: "w-auto",
      key: "event_dates",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value
              ? makeEventDatesString(sortEventDates(value, event.dates ?? []))
              : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.availability")}
        </p>
      ),
      dataIndex: "total",
      className: "w-[120px]",
      key: "total",
      render: (value, record) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {L.translate(
              sortEventDates(record.event_dates ?? [], event.dates ?? [])
                .length == (event.dates ?? []).length
                ? "CommonForm.total"
                : "CommonForm.partial"
            )}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.name")),
          "user_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "user_name",
      className: "w-auto",
      key: "user_name",
      render: (value, record) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className={`font-gilroy text-primary text-[13px] sm:text-[14px] ${
              record.info && record.info["have_vat_number"] == 0 && "font-black"
            }`}
          >
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.comp")),
          "comp",
          searchParams,
          setSearchParams
        ),
      dataIndex: "total_comp",
      className: "w-auto",
      key: "total_comp",
      render: (value, record) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeNumberWithFloatingDigits(value, 2) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.reimburse")),
          "reimburse",
          searchParams,
          setSearchParams
        ),
      dataIndex: "reimburse",
      className: "w-auto",
      key: "reimburse",
      render: (value, record) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeNumberWithFloatingDigits(value, 2) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.payment_date")}
        </p>
      ),
      dataIndex: "payment_date",
      className: "w-auto",
      key: "payment_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.contract_sign_status")}
        </p>
      ),
      dataIndex: "contract_signed",
      className: "w-[136px]",
      key: "contract_signed",
      render: (value, record) => (
        <div
          className="flex flex-row gap-2 items-center cursor-pointer "
          onClick={(e) => {
            e.stopPropagation();
            changeContractSignedStatus(record);
          }}
        >
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2"
            style={{ backgroundColor: getPaidStatusColor(value) }}
          >
            {L.translate(getPaidStatusName(value))}
          </p>
        </div>
      ),
    },
  ];

  if (event && event.status != 0) {
    columns.push({
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.status")}
        </p>
      ),
      dataIndex: "status",
      className: "w-auto",
      key: "status",
      render: (value, record) => (
        <div
          className="flex flex-row gap-2 items-center cursor-pointer "
          onClick={(e) => {
            e.stopPropagation();
            changeStatus(record);
          }}
        >
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2 "
            style={{ backgroundColor: getBidStatusColor(value) }}
          >
            {L.translate(getBidStatusName(value))}
          </p>
        </div>
      ),
    });
  }

  columns.push({
    title: () => <p className="hidden" />,
    dataIndex: "action",
    className: "w-[50px]",
    render: (
      _,
      record: {
        key: React.Key;
        id: number;
        contract_pdf: string;
        contract_signed: number;
      }
    ) => (
      <div className="flex flex-row items-center justify-start gap-2">
        <Button
          variant="icon"
          className="bg-warning !text-white hover:!bg-warning-100 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onEditCompValues(record.key as number);
          }}
        >
          <FileEditIcon className="text-white w-4 h-4" />
          <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
            {L.translate("Base.edit")}
          </span>
        </Button>
        <Button
          variant="icon"
          className="bg-active !text-white hover:!bg-blue-dark-400 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onDocuments(record.key as number);
          }}
        >
          <DocumentIcon className="text-white w-4 h-4" />
          <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
            {L.translate("CommonForm.submit_contract")}
          </span>
        </Button>
        {record.contract_pdf.length > 0 && (
          <Button
            variant="icon"
            className="bg-error !text-white hover:!bg-error-100 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onViewContractPDF(record.contract_pdf);
            }}
          >
            <DownloadIcon className="text-white w-4 h-4" />
            <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
              {L.translate("CommonForm.view_signed_contract")}
            </span>
          </Button>
        )}
        {(record.contract_signed == 1 || record.contract_signed == 2) && (
          <Button
            variant="icon"
            className="bg-purple !text-white hover:!bg-red-400 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onCancelContract(record.id);
            }}
          >
            <CloseCircleIcon className="text-white w-4 h-4" />
            <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
              {L.translate("CommonForm.cancel_contract")}
            </span>
          </Button>
        )}
      </div>
    ),
  });

  const mobileColumns: ColumnsType<CollaboratorBidType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col items-start justify-between">
            <div className="flex flex-col sm:flex-row items-start justify-between w-full">
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.event_dates
                      ? makeEventDatesString(
                          sortEventDates(selData.event_dates, event.dates ?? [])
                        )
                      : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.name")}:`}
                  </p>
                  <p
                    className={`text-primary text-[14px] font-gilroy ${
                      selData.info && selData.info["have_vat_number"] == 0
                        ? "font-black"
                        : "font-medium"
                    }`}
                  >
                    {selData.user_name}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.comp")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.comp
                      ? makeNumberWithFloatingDigits(selData.comp, 2)
                      : "-"}
                  </p>
                </div>
                {event && event.status != 0 && (
                  <div
                    className="flex flex-row items-start justify-center gap-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      changeStatus(selData);
                    }}
                  >
                    <p className="text-secondary text-[14px] font-gilroy font-medium">
                      {`${L.translate("CommonForm.status")}:`}
                    </p>
                    <div className="flex flex-row gap-2 items-center">
                      <p
                        className="font-gilroy text-white text-[14px] rounded-[8px] px-2"
                        style={{
                          backgroundColor: getBidStatusColor(selData.status),
                        }}
                      >
                        {L.translate(getBidStatusName(selData.status))}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.availability")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {L.translate(
                      sortEventDates(
                        selData.event_dates ?? [],
                        event.dates ?? []
                      ).length == (event.dates ?? []).length
                        ? "CommonForm.total"
                        : "CommonForm.partial"
                    )}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.payment_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.payment_date
                      ? makeFormattedDateString(selData.payment_date)
                      : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.reimburse")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.reimburse
                      ? makeNumberWithFloatingDigits(selData.reimburse, 2)
                      : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.contract_sign_status")}`}
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    <p
                      className="font-gilroy text-white text-[14px] rounded-[8px] px-2"
                      style={{
                        backgroundColor: getPaidStatusColor(
                          selData.contract_signed
                        ),
                      }}
                    >
                      {L.translate(getPaidStatusName(selData.contract_signed))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 mt-2 w-full">
              <Button
                variant="text"
                className="text-[14px] font-gilroy px-8 !text-white w-full md:w-auto h-[44px] rounded-[8px] hover:bg-warning-100 bg-warning"
                onClick={(e) => {
                  e.stopPropagation();
                  onEditCompValues(selData.key as number);
                }}
              >
                <FileEditIcon className="text-white w-4 h-4" />
                {L.translate("Base.edit")}
              </Button>
              <Button
                variant="text"
                className="text-[14px] font-gilroy px-8 !text-white w-full md:w-auto h-[44px] rounded-[8px] hover:bg-main bg-active"
                onClick={(e) => {
                  e.stopPropagation();
                  onDocuments(selData.key as number);
                }}
              >
                <DocumentIcon className="text-white w-4 h-4" />
                {L.translate("CommonForm.submit_contract")}
              </Button>
              {selData.contract_pdf.length > 0 && (
                <Button
                  variant="icon"
                  className="text-[14px] font-gilroy px-8 !text-white w-full md:w-auto h-[44px] rounded-[8px] hover:bg-error-100 bg-error"
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewContractPDF(selData.contract_pdf);
                  }}
                >
                  <DownloadIcon className="text-white w-4 h-4" />
                  {L.translate("CommonForm.view_signed_contract")}
                </Button>
              )}
              {(selData.contract_signed == 1 ||
                selData.contract_signed == 2) && (
                <Button
                  variant="icon"
                  className="text-[14px] font-gilroy px-8 !text-white w-full md:w-auto h-[44px] rounded-[8px] hover:!bg-red-400 bg-purple"
                  onClick={(e) => {
                    e.stopPropagation();
                    onCancelContract(selData.id);
                  }}
                >
                  <CloseCircleIcon className="text-white w-4 h-4" />
                  {L.translate("CommonForm.cancel_contract")}
                </Button>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-4">
        <InputWithoutLabel
          placeholder={String(L.translate("CommonForm.name"))}
          className="w-full"
          value={searchParams.user_name}
          showClose={searchParams.user_name!.length > 0}
          clickedClose={() =>
            setSearchParams({ ...searchParams, user_name: "" })
          }
          onChange={(e) =>
            setSearchParams({ ...searchParams, user_name: e.target.value })
          }
        />
        <TailwindDatePicker
          placeholder={String(L.translate("CommonForm.event_date"))}
          required
          displayFormat="DD/MM/YYYY"
          className="z-30"
          value={searchParams.event_date ?? ""}
          onChange={(strDate?: string) =>
            setSearchParams({ ...searchParams, event_date: strDate ?? "" })
          }
        />
        {/*
          <MonthPicker
            placeholder={String(L.translate("CommonForm.event_date"))}
            value={searchParams.event_date ?? ""}
            onChange={(strDate?: string) =>
              setSearchParams({ ...searchParams, event_date: strDate ?? "" })
            }
          />
        */}
        <MonthPicker
          placeholder={String(L.translate("CommonForm.payment_date"))}
          value={searchParams.payment_date ?? ""}
          onChange={(strDate?: string) =>
            setSearchParams({ ...searchParams, payment_date: strDate ?? "" })
          }
        />
        <DropdownWithoutLabel
          placeholder={String(L.translate("CommonForm.contract_signed"))}
          className="w-full"
          data={[
            { id: -1, name: String(L.translate("CommonForm.status")) },
            ...PaidStatus.map((item) => ({
              ...item,
              name: String(L.translate(item.name)),
            })),
          ]}
          onChange={(e: number) =>
            setSearchParams({ ...searchParams, status: e })
          }
        />
      </div>
      <div className={`relative w-full ${!isMobile && "mt-4"}`}>
        <Table
          locale={{
            emptyText: (
              <Empty
                description={
                  <p className="font-gilroy-semibold text-secondary text-[14px]">
                    {L.translate("Base.no_data")}
                  </p>
                }
              />
            ),
          }}
          rowSelection={event.status >= 0 ? rowSelection : undefined}
          showSorterTooltip={false}
          columns={isMobile ? mobileColumns : columns}
          dataSource={dataSource}
          loading={isLoading || isCancellingContract}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => onClickedBid(record),
            };
          }}
          pagination={{
            total: total,
            onChange: onPageChange,
            position: ["bottomCenter"],
            defaultPageSize: LIST_PAGE_SIZE,
            showSizeChanger: false,
          }}
        />
      </div>
    </>
  );
};

export default EventBidListView;
